@import 'global.scss';

.layout {
    display: flex;
    height: 100vh;
    flex-direction: column;

    // target only Safari
    // TODO: check why height: 100% doesn't work on Chrome
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            height: 100%;
        }
    }

    .footer-wrapper {
        grid-area: footer;
        flex-direction: column-reverse;
        justify-self: end;
        z-index: 1;
    }
}