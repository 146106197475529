@import '../../../../layout/theme.scss';

.sub-section-label {
    color: #343A40;
    padding: 20px 0px 5px 0px;
    font-weight: 600;
}

.sourceSummaryTable {
    border-spacing: 0;
    width: 100%;
    margin-top: 2rem;
    text-align: center;

    thead {
        tr {
            th {
                padding: 0.6rem 0.8rem;
                font-weight: 500;
                font-size: 1.5rem;
            }

            &:first-child {
                background-color: rgba($orange, 0.5);

                th:not(:last-child) {
                    border-right: 1px solid white;
                }
            }

            &:nth-child(2) {

                th:first-child,
                th:nth-child(4),
                th:nth-child(8),
                th:nth-child(9) {
                    border-right: 1px solid white;
                }
            }

            & {
                background-color: rgba($orange, 0.2);
            }
        }
    }

    tbody {
        tr:nth-child(even) {
            background-color: $grey-light;
        }

        tr {
            td {
                padding: 0.6rem 0.8rem;
                font-size: 1.5rem;
            }
        }
    }
}

.sourceSummaryTableWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1.8rem;
    border-radius: 0.3rem;
    padding: 5.6rem;
    background-color: #ffffff;
    position: relative;
}

.source-table-edit {
    border: none;
    background-color: transparent;
    padding: 0;
    font-family: Lato;
    font-size: 14px;
    color: $orange;
    position: absolute;
    top: 5.6rem;
    right: 5.6rem;
}