@import '../../../layout/theme.scss';

.sources {
    &:last-child {
        height: 100%;
    }

    // target only Safari
    // TODO: check what's causing flexbox unresponsiveness on safari
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            &:last-child {
                height: 270px;
                overflow: auto;
            }
        }
    }
}

.source {
    &__form__container {
        padding-bottom: 1rem;
    }

    &__form {
        margin-top: 2px;
        width: 126rem;
        display: grid;
        grid-template-columns: 10rem 1fr 1fr 10rem 1fr;
        margin-bottom: 2px;
        position: relative;
    }

    &__icon {
        margin-left: 0.8rem;
    }

    &__no-sources {
        grid-row: 1;
        grid-column: 2/5;
        margin-top: 6px;
    }
}