@import 'theme.scss';

*,
*::after,
*::before {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Lato !important;
    background-color: #f8f8f8;
    color: $grey-dark;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li {
    margin: 0;
}

ul[class],
ol[class] {
    padding: 0;
    list-style: none;
}

img {
    max-width: 100%;
    display: block;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

// It removes blue border when focused on Select Component
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: black !important;
    border-width: 1px !important;
}

.MuiInputBase-root,
.MuiOutlinedInput-root {
    font-family: Lato !important;
}

.no-data-container {
    padding: 20px 0px;
    color: grey;
    font-size: 14px;
}

.label-red {
    color: $red !important;
}

.label-xs {
    font-size: $font-xs;
}