@import '../../layout/theme.scss';

.dialog-container {
    position: relative;
    width: 100%;
    min-width: 40rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
}

.container {
    position: relative;
    width: 100rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 5rem 9rem 5rem 5rem;
    max-height: 85%;
    margin: 0 auto;
    height: inherit;


    // target only Safari
    // TODO: check what's causing flexbox unresponsiveness on safari
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            margin-bottom: 11%;
        }
    }
}

.button-container {
    padding: 30px;
}

.form {
    display: flex;
    justify-content: center;
    width: 100%;

    &__form-row {
        &.row-3 {
            display: grid;
            grid-template-rows: repeat(3, 0.1fr);
            grid-gap: 3rem;
            width: 40rem;

            &>div>div>select {
                padding: 14px 32px 14px 14px;
            }

            &> :last-child {
                margin-bottom: 3rem;
            }
        }

        &.row-4 {
            display: grid;
            grid-template-rows: 0.1fr;
            grid-gap: 3rem;
            width: 20rem;

            &> :last-child {
                margin-bottom: 3rem;
            }
        }
    }

    &__discount {
        display: flex;
        align-items: center;
        white-space: nowrap;

        & p {
            color: rgba(35, 47, 62, 0.5);
            margin-right: 2.1rem;
            font-size: $font-sm;
        }

        &>div>div>input {
            height: 30px;
        }
    }
}

.MuiOutlinedInput-input {
    padding: 1.1rem 2.2rem;
}

.empty-container {
    width: 30rem;
}