@import '../../layout/theme.scss';
.tabs {
    height: 100%;
    width: 126rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    position: relative;
    z-index: 4;
    &__tabs-container {
        margin-bottom: 6rem;
        border-bottom: 1px solid rgba(35, 47, 62, 0.1);
        &:first-child {
            padding-left: 6rem;
        }
    }
    &__tab {
        display: inline-block;
        color: rgba(35, 47, 62, 0.5);
        margin-right: 6rem;
        cursor: pointer;
        position: relative;
        padding: 0.6rem 1rem;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: $blue;
            position: absolute;
            bottom: -2px;
            left: 0;
            transform: scaleX(0);
            transform-origin: 0;
            transition: transform 0.2s ease-in-out;
        }
        &-active.tabs__tab::after {
            transform: scaleX(1);
        }
        &-active.tabs__tab {
            color: $blue;
        }
    }

    &__button-export {
        position: absolute !important;
        top: -100px;
        right: 0;
        left: unset;
        text-decoration: none;
    }

    &__button-reset {
        position: absolute !important;
        top: -40px !important;
        right: 0;
        left: unset;
    }

    &__button-back {
        position: absolute;
        top: -85px;
        left: 0;
        right: unset;
    }

    &__loader-wrapper {
        margin-top: 18rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
