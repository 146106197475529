@import '../../layout/theme.scss';

.sources-hint,
.customer-details-hint {
    width: 750px;
    margin-top: 10px;
}

.customer-details-hint {

    .details-container {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-auto-rows: 40px;
        row-gap: 10px;
        width: 700px;
        justify-content: space-between;
        align-items: center;

        .details-item {
            display: flex;
            align-items: center;

            .MuiSelect-outlined {
                padding: 8px;
            }

            input {
                text-align: start;
                padding-left: 10px;
            }

            .Mui-checked {
                color: #ff9900;
            }

            span {
                font-size: 14px;
                font-family: Lato;
            }

            .tco-checkbox {
                svg {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }

        .last-updated-day-container {
            margin-top: -30px;
            font-size: 13px;
            color: #6C757D;
        }

        .get-details {
            background-color: #ff9900;
            padding: 0.6rem 2rem;
            color: #ffffff;
            font-weight: bold;
            font-size: 1.3rem;
            margin-left: 10px;
            text-transform: none;


        }

        .get-details-disabled {
            background-color: #ffc165;
            padding: 0.6rem 2rem;
            color: #ffffff;
            font-weight: bold;
            font-size: 1.3rem;
            margin-left: 10px;
            text-transform: none;
        }
    }
}

#link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-transform: none;
    color: #2171B5;
    display: inline;

    &.disabled {
        opacity: 0.7;
    }
}

.existing-data-sources-container {

    .display-flex {
        display: flex;
    }

    .align-items-center {
        align-items: center;
    }

    .card-container {
        background-color: white;
        padding: 15px 20px;
        margin: 15px 0px;
    }

    .label-value-container {
        .value {
            display: inline-flex;
            font-weight: bold;
            font-size: 18px;
        }

        .label {
            display: inline-flex;
            font-size: 12px;
            color: grey;
            margin-right: 3px;
        }

        .unit {
            @extend .label;
            padding-left: 3px;
        }
    }

    .table-header-cell {
        font-size: 14px;
        font-weight: bold;
    }

    .table-cell {
        font-size: 14px;
    }

    .last-updated-day {
        color: grey;
        font-size: 14px;
        padding-left: 10px;
    }

}

.section-heading {
    margin-top: 10px;
    margin-bottom: 10px;
}

.select-tco {
    display: flex;
    width: 750px;
    margin-top: 10px;
    margin-bottom: 14px;
    align-items: baseline;

    span {
        margin-right: 10px;
    }

    .MuiSelect-outlined {
        padding: 8px;
        min-width: 140px;
    }
}

.sources,
.customer-details {
    width: 126rem;
    display: grid;
    grid-template-areas: 'counter details dataChange storageTier retention';
    grid-template-columns: 10rem 1fr 1fr 10rem 1fr;
}

.source {
    grid-area: counter;
    margin-right: 0.8rem;
}

.details {
    grid-area: details;
}

.dataChange {
    grid-area: dataChange;
}

.storageTier {
    grid-area: storageTier;
}

.retention {
    grid-area: retention;
}

.row {
    display: flex;
}

.details-sub-header {
    display: grid;
    grid-template-columns: 2.3fr 1fr 1fr
}

.dataChange-sub-header {
    display: grid;
    grid-template-columns: 1fr 0.9fr 0.9fr 1.2fr;
}

.add-group {
    justify-content: center;
    align-items: center;

    &__button {
        margin-top: 0.8rem;
        padding: 0.6rem 2.25rem;
        background-color: $orange;
        border-radius: 3px;
        color: #ffffff;
        font-weight: bold;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 1.3rem;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.my-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
