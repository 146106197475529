@import '../../layout/theme.scss';

.content-container {
    overflow: auto;
    margin-top: 7rem;

    .home {
        height: calc(100vh - 11rem);
        align-self: start;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin: 0 auto 0 auto;
        max-width: 130rem;

        .linear-stepper-wrapper {
            min-height: 77px;
            display: flex;
            justify-content: center;
        }

        .sources-form-wrapper {
            height: 100%;
            position: relative;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            margin: auto;
            max-width: 100%;
        }

        .MuiCircularProgress-svg {
            color: #146eb4;
        }
    }
}