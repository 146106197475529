.import-dialog {
    .label1 {
        border: 1px solid #CED4DA;
        margin-left: 25px;
        padding: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 290px;
        cursor: pointer;
    }

    .label2 {
        font-size: 14px;
        color: #212529;
        background: #E9ECEF;
        border: 1px solid #CED4DA;
        border-left: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 7px 9px;
        float: right;
        cursor: pointer;
    }
}