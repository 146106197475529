@import '../../../../layout/theme.scss';

.tables {
    width: 60%;
    padding-right: 6rem;
}

.width-100 {
    width: 100%;
}

.backupTable {
    border-spacing: 0;
    width: 100%;
    margin: 3rem 0rem;

    thead {
        tr {
            color: #ffffff;

            th:not(:first-child) {
                background-color: #8e939b;
            }

            th:first-child {
                width: 43.5%;
            }

            th {
                padding: 1.1rem 2.7rem;
                border-bottom: 1rem solid #ffffff;
                white-space: nowrap;
                text-align: left;
            }

            th:not(:first-child) {
                text-align: center;
            }
        }
    }

    tbody {
        tr:nth-child(odd) {
            background-color: $grey-light;
        }

        tr {
            td:first-child {
                background-color: rgba(orange, 0.2);
                text-align: left;
                padding: 0.8rem 1.3rem;
            }

            td {
                padding: 0.8rem 0.1rem;
            }

            td:not(:first-child) {
                text-align: center;
            }
        }

        tr:last-child {
            background-color: rgba(orange, 0.2);
        }
    }

    .cell-with-tooltip {
        position: relative;
        width: 100%;
        display: inline-block;

        &>svg {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

.priceTable {
    border-spacing: 0;
    width: 100%;

    thead {
        tr {
            th:first-child {
                width: 43.5%;
            }

            th {
                padding: 1.1rem 1.3rem;
                white-space: nowrap;
                text-align: left;
                background-color: $grey;
                font-weight: 500;
            }

            th:not(:first-child) {
                text-align: center;
            }
        }
    }

    tbody {
        tr:nth-child(even) {
            background-color: $grey-light;
        }

        tr {
            td:first-child {
                padding: 0.8rem 1.7rem;
            }

            td {
                padding: 0.8rem 0.1rem;
            }

            td:not(:first-child) {
                text-align: center;
            }
        }
    }
}

.backupTableWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 1.8rem;
    border-radius: 0.3rem;
    padding: 5.6rem;
    background-color: #ffffff;
}

.flexDirectionCol {
    flex-direction: column;
}

.discounts-edit {
    border: none;
    background: #e9ebec;
    padding: 0;
    font-family: Lato;
    font-size: 1.4rem;
    color: $orange;
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    z-index: 1;
}

.priceTableWrapper {
    position: relative;
}