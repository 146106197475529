@import '../../layout/theme.scss';

.dialog>.MuiPaper-root {
    border-radius: 0.2rem;
    padding: 3rem 4rem;
    max-width: max-content;

    .dialog-close-icon {
        cursor: pointer;
        position: absolute;
        top: 1.2rem;
        right: 1.2rem;
        min-width: 3.3rem;
        min-height: 3.3rem;
    }

    .MuiDialogTitle-root {
        padding: 0;
        margin-bottom: 3.5rem;

        >h2 {
            color: $blue;
            font-size: $font-bg;
            font-weight: bold;
            line-height: 1.8rem;
        }
    }

    .MuiDialogContent-root {
        padding: 0 0 8rem;
    }

    .dialog-content {
        background-color: #f8f8f8;
        position: relative;
    }
}

.confirm-dialog {
    width: 450px;
    background-color: white;
    display: flex;
    flex-direction: column;
    font-family: Lato;

    .confirm-dialog-content {
        flex-grow: 1;
        border-bottom: 1px solid #e9ebec;
        padding: 2rem 0rem;
        overflow-y: auto;
        min-height: 12rem;

        p {
            margin-top: 10px;
        }

        &>span {
            color: grey;
        }

        .Mui-focused {
            color: grey;
        }

        .MuiFormControl-root {
            display: block;
            margin-top: 20px;

            label {
                font-size: 15px;
                font-family: Lato;
                min-width: 86px;
            }

            .MuiFormControlLabel-root {
                margin: 0 10px;

                span {
                    font-size: 14px;
                    font-family: Lato;
                }

                .Mui-checked {
                    color: #ff9900;
                }
            }

            .flexDirectionRow {
                flex-direction: row;
            }
        }
    }

    .confirm-dialog-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        height: 40px;

        a {
            text-decoration: none;
        }
    }
}

.credit-dialog {
    width: 495px;

    .credit-dialog-container {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        justify-content: space-between;
        color: black;
        margin-top: 15px;

        .input {
            width: 130px;
            height: 30px;
        }

        .button {
            min-width: 90px;
            max-width: 130px;
            height: 40px;
            padding: 5px 10px;
            background-color: orange;
            text-transform: none;
            border-radius: 3px;
            color: #ffffff;
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 20px;
            font-family: 'Lato';
            font-weight: bold;

            &:focus {
                outline: none;
            }
        }

        .disabled.button {
            background-color: #ffc165;
            cursor: default;
        }

        .font-bold {
            font-weight: bold;
        }
    }
}