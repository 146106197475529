.loader .loader-content {
    display: none;
}

.loader.show {
    position: relative;

    &>.loader-content {
        align-items: center;
        background: rgba(248, 248, 248, 0.8);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1030;
        user-select: none;
    }

    .loader-text {
        color: #000;
        font-size: 1rem;
    }


    &:not(.full-page) {
        min-height: 5rem;
    }

    &.full-page {
        &>.loader-content {
            position: fixed;
            width: 100vw;
            height: 100vh;
            z-index: 1070;
        }
    }
}